<template>
	<div class="manage_home_wrap">
		<!-- <div class="bxoli">

      </div> -->
		<a-modal title="Title" class="headernone" :visible="visible" @cancel="handleCancel" :maskClosable="false">
			<p class="gongsix">恭喜！获得以下权益</p>
			<div class="gongtow">恭喜您成为快洋淘浏览器的用户，您已获得一张100元的新人优惠券，在购买设备时可进行金额抵扣。</div>
			<div class="gongset">
				<p class="setleft"></p>
				<div class="setconten">
					<p>18元新人优惠券</p>
					<span>购买设备时可进行金额抵扣</span>
					<div class="song">送</div>
				</div>
			</div>
			<div class="setbut" @click="buyEquipment">立即使用</div>
			<div class="setdelde" @click="handleCancel"></div>
		</a-modal>
		<div v-if="Novice == 2" class="dakai">
			<p @click="openk">打开新手指引</p>
		</div>
		<div v-if="Novice == 1" class="yindao">
			<div class="dao-top">
				<div class="dtop-left">
					<p class="tiot">新手引导</p>
					<p class="txet">跟着新手引导，学习怎么使用快洋淘浏览器，快速掌握跨境秘籍</p>
				</div>
				<div class="dtop-right" @click="shut">关闭新手指引</div>
			</div>
			<div class="dao-botmm">
				<div class="nome1">
					<div class="nome-left">
						<img src="../../../assets/img/yindao/onme1.png" alt="" />
					</div>
					<div class="nome-right">
						<p class="nome-rtitle">
							<img src="../../../assets/img/yindao/01.png" alt="" />
							新建环境
						</p>
						<div class="nome-center">在快洋淘浏览器，新建环境并填写对应账号的平台与账密，即可在打开账号时自动填充账密，保护您的财产安全</div>
						<div class="nome-but" @click="newEnvironment">新建环境</div>
					</div>
				</div>
				<div class="nome1">
					<div class="nome-left">
						<img src="../../../assets/img/yindao/xi1.png" alt="" />
					</div>
					<div class="nome-right">
						<p class="nome-rtitle">
							<img src="../../../assets/img/yindao/02.png" alt="" />
							购买设备
						</p>
						<div class="nome-center">在快洋淘浏览器购买设备并绑定账号后，即可轻松提速，跨境访问更安全</div>
						<div class="nome-but" @click="buyEquipment">购买设备</div>
					</div>
				</div>
				<div class="nome1">
					<div class="nome-left">
						<img src="../../../assets/img/yindao/xi2.png" alt="" />
					</div>
					<div class="nome-right">
						<p class="nome-rtitle">
							<img src="../../../assets/img/yindao/03.png" alt="" />
							查看使用指南
						</p>
						<div class="nome-center">了解账号创建图文视频，如何购买设备，添加企业成员，分配账号权限</div>
						<div class="nome-but">
							<a href="https://www.yuque.com/docs/share/d905afdf-69d0-47f5-87d8-ad0888621307?# ">查看教程</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="top_wrap">
			<div class="top_cell top_left" v-if="isrole == 0">
				<div class="t_cell_left" style="background-color: #f29690"></div>
				<div class="top_cell_ico ico_only"></div>
				<div class="top_line"></div>
				<div class="top_content">
					<div class="top_cont_cell">
						<div class="top_num">
							{{ no_auth_environment_count }}
						</div>
						<div class="top_txt">待授权环境</div>
					</div>
					<div class="top_cont_cell">
						<div class="top_num">{{ no_bind_count }}</div>
						<div class="top_txt">待绑定设备的环境</div>
					</div>
					<div class="top_cont_cell">
						<div class="top_num">{{ urgent_renewal_count }}</div>
						<div class="top_txt">急需付费</div>
					</div>
				</div>
			</div>

			<div class="top_cell top_middle" v-if="isrole == 0">
				<div class="t_cell_left" style="background-color: #ffbe00"></div>
				<div class="top_cell_ico ico_only"></div>
				<div class="top_line"></div>
				<div class="top_content">
					<div class="top_cont_cell">
						<div class="top_num">{{ about_expire_count }}</div>
						<div class="top_txt">即将过期设备</div>
					</div>
					<div class="top_cont_cell">
						<div class="top_num">{{ expired_count }}</div>
						<div class="top_txt">已过期设备</div>
					</div>
					<div class="top_cont_cell">
						<div class="top_num">{{ no_bind_env_count }}</div>
						<div class="top_txt">待绑定环境的设备</div>
					</div>
				</div>
			</div>

			<div class="top_cell top_right">
				<div class="t_cell_left" style="background-color: #47d2b6"></div>
				<div class="top_cell_ico ico_only"></div>
				<div class="top_line"></div>
				<div class="top_yuer">
					<div class="top_num">{{ balance }}</div>
					<div class="top_txt">账户余额</div>
				</div>
				<div class="chongzhi_btn" @click="go_page('charge_fee')">充值</div>
			</div>
		</div>

		<div class="down_wrap">
			<div class="down_top">
				<div class="left">
					<div class="top_btn btn_1" :class="[env_common == 0 ? 'btn_be_check' : '']" @click="click_env_common(0)">所有环境</div>
					<div class="top_btn" :class="[env_common == 1 ? 'btn_be_check' : '']" @click="click_env_common(1)">常用环境</div>
					<div class="top_btn" :class="[recent_open == 1 ? 'btn_be_check' : '']" @click="click_recent">最近打开</div>
				</div>
				<div class="right">
					<div class="top_btn btn_be_check" @click="buyEquipment" v-if="isrole == 0">购买设备</div>
					<div class="top_btn blue" @click="newEnvironment" v-if="isrole == 0">新建环境</div>
					<a-input-search
						class="saerch-input"
						placeholder="搜索设备名称/设备环境/设备信息"
						v-model="standard_config.keyword"
						@search="handle_search" />
					<a-select v-model="standard_config.platform_id" class="search-select" placeholder="筛选平台">
						<a-select-option v-for="item in drawer_platformlist" :key="item.id">
							{{ item.site }}
						</a-select-option>
					</a-select>

					<div class="search-btn" @click="handle_search">搜索</div>
					<div class="search-reset" @click="searchReset">清空</div>
				</div>
			</div>

			<a-table
				:columns="table_column"
				:data-source="table_data"
				:row-key="(r, i) => i.toString()"
				class="down_table"
				:pagination="pagination"
				:loading="table_loading"
				@change="handleTableChange">
				<div slot="cell_platform" slot-scope="text, record">{{ text }}{{ record.site }}</div>

				<div slot="cell_tag" slot-scope="text">
					<span v-for="item in text" :key="item.id">
						{{ item.tag }}
					</span>
				</div>

				<div slot="cell_info" slot-scope="text, record">
					<div class="cell_info">
						<p>{{ text }}</p>
						<template v-if="record.bind_status">
							<span v-if="record.device_area_title">{{ record.device_area_title }},{{ record.device_package_title }}</span>
							<!-- <span v-if="!record.device_area_title">未绑定</span> -->
						</template>
						<template v-else>
							<span>未绑定</span>
						</template>
					</div>
				</div>
				<template slot="device_name" slot-scope="text, record">
					{{ record.device_type == 3 ? "本地直连" : record.device_name }}
				</template>
				<template slot="device_info" slot-scope="text, record">
					{{ format_devicestate(text, record) }}
				</template>
				<div slot="cell_last" slot-scope="text">
					{{ text }}
				</div>
				<div slot="cell_operate" slot-scope="text, record" v-if="record.bind_status || record.device_type == 3" class="operate">
					<div class="btns">
						<!-- 打开  -->
						<button
							class="open"
							@click="go_open(record)"
							:disabled="$store.state.curEnvStatusList[record.id.toString()] === 'loading'"
							v-if="
								$store.state.curEnvStatusList[record.id.toString()] === 'standby' ||
								$store.state.curEnvStatusList[record.id.toString()] === 'loading'
							">
							<img width="18" height="18" src="@/assets/images/add_env/icon-chrome.png" alt="" />
							<span>{{ $store.state.curEnvStatusList[record.id.toString()] === "loading" ? "加载中" : "打开" }}</span>
						</button>
						<!-- 关闭  -->
						<template v-else>
							<button class="close" @click="batchClose(record.id)">
								<img width="18" height="18" src="@/assets/images/add_env/icon-chrome.png" alt="" />
								<span>关闭</span>
							</button>
							<img
								class="icon_bringToFront"
								width="16"
								height="16"
								src="@/assets/images/env/icon_computer.png"
								alt=""
								title="显示窗口"
								@click="bringToFront(record.id)" />
						</template>
					</div>
				</div>
			</a-table>
		</div>
	</div>
</template>
<script>
import { environment_index, environment_first_count } from "@/api/home";
import { user_info } from "@/api/login";
import { environment_info, environment_get_config, getEnvironment_choose } from "@/api/environment.js";
import { languageList } from "@/utils/languageList";
export default {
	data() {
		return {
			ModalText: "Content of the modal",
			visible: false,
			no_auth_environment_count: 0,
			no_bind_count: 0,
			urgent_renewal_count: 0,
			about_expire_count: 0,
			expired_count: 0,
			// 禁止打开
			disableOpen: false,
			no_bind_env_count: 0,
			balance: null,
			table_loading: false,
			isrole: null,
			Novice: 1,
			env_common: 0, // 常用1 默认0
			recent_open: 0,
			plate_list: [
				{
					name: "所有平台",
					value: "",
				},
				{
					name: "亚马逊",
					value: "ymx",
				},
				{
					name: "ebay",
					value: "ebay",
				},
			],
			platform_id: "",
			pagination: {
				pageNum: 1, //当前页数
				pageSize: 20, //每页条数
				total: 0,
				showTotal: total => `共 ${total} 条`, // 显示总数
			},

			table_column: [
				{
					title: "环境名称",
					dataIndex: "env_name",
					// scopedSlots: { customRender: "cell_envname" },
					width: 150,
					ellipsis: true,
					show: true,
				},
				{
					title: "环境ID",
					dataIndex: "id",
					show: true,
				},
				{
					title: "所属平台",
					dataIndex: "country",
					scopedSlots: { customRender: "cell_platform" },
					show: true,
				},
				{
					title: "设备名称",
					dataIndex: "device_name",
					scopedSlots: { customRender: "device_name" },
					show: true,
					width: 150,
					ellipsis: true,
				},
				{
					title: "设备信息",
					dataIndex: "bind_status",
					scopedSlots: { customRender: "device_info" },
					show: true,
					width: 150,
					ellipsis: true,
				},
				{
					title: "最后登陆者",
					dataIndex: "last_username",
					scopedSlots: { customRender: "cell_last" },
					show: true,
				},
				{
					title: "最后登陆时间",
					dataIndex: "last_login_time",
					show: true,
				},
				{
					dataIndex: "operation",
					slots: { title: "operaTitle" },
					scopedSlots: { customRender: "cell_operate" },
					show: true,
				},
				// {
				//   title: "标签",
				//   dataIndex: "tag",
				//   scopedSlots: { customRender: "cell_tag" },
				//   show: false,
				// },
				// {
				//   title: "企业简称",
				//   dataIndex: "business_short",
				//   show: false,
				// },
				// {
				//   title: "创建者",
				//   dataIndex: "username",
				//   show: false,
				// },
			],
			table_data: [],

			// 平台列表
			drawer_platformlist: [],
			standard_config: {
				// 搜索 输入框
				keyword: undefined,
				// 平台筛选
				platform_id: undefined,
			},

			websocketObj: null,
			websocket_port: 42536,
			check_record: null, //选中的表格行
		};
	},
	computed: {
		envExCheck() {
			return this.$store.state.envExCheck;
		},
	},
	watch: {
		async envExCheck(newVal) {
			//恢复打开按钮的可用状态
			if (newVal.open_success == 1) {
				this.$message.success("已成功打开环境，请点击桌面底部快洋淘浏览器的新窗口前往店铺平台");
				this.changeEnvStatus(this.check_record.id, "standby");
				this.disableOpen = false; //成功打开环境
			} else {
				this.$message.error("打开失败，请重试");
				this.changeEnvStatus(this.check_record.id, "standby");
			}
		},
	},
	filters: {
		envStatusFilter(val) {
			switch (val) {
				case "running":
					return "运行中";
				case "standby":
					return "打开";
				case "loading":
					return "加载中";
				default:
					return "打开";
			}
		},
	},
	created() {
		this.top_data();
		this.get_userinfo();

		this.get_tabledata();

		this.getFil();
	},
	mounted() {
		this.$nextTick(() => {
			if (localStorage.getItem("Novice")) {
				this.Novice = localStorage.getItem("Novice");
			}
		});
	},
	methods: {
		handleCancel(e) {
			this.visible = false;
			localStorage.isLogin = 2;
		},
		openk() {
			this.Novice = 1;
			localStorage.setItem("Novice", 1);
		},
		shut() {
			this.Novice = 2;
			localStorage.setItem("Novice", 2);
		},
		async getFil() {
			let { data } = await getEnvironment_choose();
			this.drawer_platformlist = data.data.platform;
		},
		// 清空搜索
		searchReset() {
			this.standard_config = {
				keyword: undefined,
				platform_id: undefined,
			};
			this.get_tabledata();
		},
		// 购买设备
		buyEquipment() {
			if (this.isrole !== 0) return this.$message.error("您没有权限1");
			localStorage.isLogin = 2;
			this.$router.push({ name: "manage_buyequipment" });
		},
		// 新建环境
		newEnvironment() {
			if (this.isrole !== 0) return this.$message.error("您没有权限1");
			this.$router.push({ name: "manage_addenv" });
		},
		handle_search() {
			this.pagination.pageNum = 1;
			this.get_tabledata();
		},
		async get_userinfo() {
			let { data } = await user_info({
				user_role: JSON.parse(localStorage.member).user_role,
			});
			if (data.code == 200) {
				localStorage.member = JSON.stringify(data.data.member);
				this.balance = data.data.member.balance;
				this.isrole = data.data.member.user_role;
				if (data.data.member.is_first_login == 1) {
					this.visible = true;
				} else if (data.data.member.is_first_login == 2) {
					this.visible = false;
				}
			}
		},
		go_page(name) {
			this.$router.push({ name: name });
		},
		async get_tabledata() {
			this.table_loading = true;
			let { data } = await environment_index({
				...this.standard_config,
				env_common: this.env_common,
				recent_open: this.recent_open,
				status: 0,
				pagesize: 20,
				page: this.pagination.pageNum,
			});
			this.table_loading = false;
			if (data.code == 200) {
				this.pagination.total = data.data.total;
				this.table_data = data.data.list;
				this.getCurEnvStatusList();
			}
		},
		click_env_common(data) {
			this.env_common = data;
			this.recent_open = 0;
			this.get_tabledata();
		},
		click_recent() {
			this.env_common = 0;
			this.recent_open = 1;
			this.get_tabledata();
		},
		filterOptionEvent(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		},
		select_handleChange(data) {
			this.platform_id = data;
		},

		async top_data() {
			let { data } = await environment_first_count();
			if (data.code == 200) {
				this.no_auth_environment_count = data.data.environment.no_auth_environment_count;
				this.no_bind_count = data.data.environment.no_bind_count;
				this.urgent_renewal_count = data.data.environment.urgent_renewal_count;

				this.about_expire_count = data.data.device.about_expire_count;
				this.expired_count = data.data.device.expired_count;
				this.no_bind_env_count = data.data.device.no_bind_env_count;
			}
		},

		async onSearch(keywords) {
			let { data } = await environment_index({
				keywords: keywords,
				platform_id: this.platform_id,
				recent_open: this.recent_open,
				env_common: this.env_common,
				pagesize: 20,
				page: this.pagination.pageNum,
			});
			if (data.code == 200) {
				this.pagination.total = data.data.total;
			}
		},
		//表格 切换页码
		handleTableChange(pagination) {
			this.pagination.pageNum = pagination.current;
			this.get_tabledata();
		},
		go_open(record) {
			this.changeEnvStatus(record.id, "loading");
			this.check_record = record;
			this.websocket_open_client();
		},
		initWebsocket() {
			this.webSocketObj = new WebSocket("ws://" + "127.0.0.1:" + this.websocket_port);
			this.webSocketObj.onmessage = this.onMessage;
			this.webSocketObj.onopen = this.onOpen;
			this.webSocketObj.onerror = this.onError;
			this.webSocketObj.onclose = this.onClose;
		},
		//接收到消息的回调方法
		onMessage(evt) {
			if (evt.message == "/connection/fail") {
				this.$message.error(evt.data);
			}
		},
		async websocket_open_client() {
			let { data } = await environment_get_config({
				id: this.check_record.id,
				env_id: this.check_record.id,
			});
			let user = localStorage.user ? JSON.parse(localStorage.member || {}) : {};
			let req = {
				...this.check_record,
				...data.data,
				// transport_ip: "101.89.44.90:40000",
			};

			// if (req.location.is_baseon_location) {
			// 	req.location.longitude = req.lngwgs;
			// 	req.location.latitude = req.latwgs;
			// }
			// 将指纹参数解构到最外层
			try {
				let { fingerprint, ...reset } = req;
				req = {
					...reset,
					...fingerprint,
					user,
				};
			} catch (error) {}
			if (req.location.is_baseon_location) {
				req.location.longitude = req.lngwgs;
				req.location.latitude = req.latwgs;
			}
			//从国家语言映射表中匹配ip地址对应的语言，匹配不到则默认为英语en
			const languageItem = languageList.find(i => i.country.includes(req.country));
			//判断是否根据ip生成时区。首先调用第三方库查找，找不到则在本地列表查找，再找不到则为""
			try {
				if (!req.is_custom_timezone) {
					req.timezone = find(req.latwgs, req.lngwgs)[0];
				}
			} catch (error) {
				const timezoneAlt = timezoneList[req.areacode];
				if (timezoneAlt) {
					req.timezone = timezoneAlt[0];
				} else {
					req.timezone = "";
				}
			}

			req.lang = languageItem ? languageItem.value : "en";
			req.device_ip = `${req.device_ip}`;
			// 是否成功从语言列表(languageList)中匹配到语言
			req.isIPLanguageOK = languageItem ? true : false;
			//插件对应的字段，req.extensions,[{id:,guid:,download_url:""}]
			if (data.code == 200) {
				let c_1 = {
					message: "/connection/proxy",
					// serverIp: this.check_record.device_ip,
					// browser_id: this.check_record.id,
					// env_name: this.check_record.env_name,
					alldata: req,
					token: localStorage.token,
				};
				let b_1 = this.$encrypt(JSON.stringify(c_1));
				this.$store.state.websocket.send(b_1);
			}
		},
		//连接成功建立的回调方法
		onOpen() {
			if (this.webSocketObj.readyState === 1) {
				this.websocket_open_client();
			}
		},
		//连接发生错误
		onError() {
			if (this.websocket_port <= 42540) {
				setTimeout(() => {
					this.onClose();
					this.websocket_port += 1;
					this.initWebsocket();
				}, 1000);
			} else {
				this.$message.error("打开失败,websocket通信异常");
				this.onClose();
			}
		},
		//连接关闭的回调方法
		onClose() {
			this.webSocketObj && this.webSocketObj.close && this.webSocketObj.close();
		},
		// 获取当前分页环境列表状态
		getCurEnvStatusList() {
			if (this.table_data.length > 0) {
				let env_list = this.table_data.map(item => item.id).join(",");
				const params = {
					message: "/connection/env_status_msg",
					env_list,
				};
				this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
			}
		},
		// 将指定环境状态修改未加载中
		changeEnvStatus(env_id, target_status) {
			let id = env_id.toString();
			let status = JSON.parse(JSON.stringify(this.$store.state.curEnvStatusList));
			status[id] = target_status;
			this.$store.commit("updateCurEnvStatusList", status);
		},
		// 批量关闭环境
		batchClose(id) {
			if (id) {
				const params = {
					message: "/connection/close_env",
					data: [`${id}`],
				};
				this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
			} else {
				if (this.selectedRowKeys.length < 1) {
					return this.$message.error("请选择要关闭的环境");
				}
				const data = this.selectedRowKeys.map(item => `${this.table_data[item].id}`);
				const params = {
					message: "/connection/close_env",
					data,
				};
				this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
			}
		},
		// 显示窗口
		bringToFront(id) {
			const params = {
				message: "/connection/bringtofront",
				data: `${id}`,
			};
			this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
		},
		format_devicestate(state, record) {
			if (record.device_type == 3) {
				return "本地IP";
			}
			if (!state) {
				return "未绑定";
			} else if (record.describe["user_ip"]) {
				return record.describe["user_ip"];
			} else {
				return record.device_ip + " " + record.device_area_title + " " + record.device_package_title;
			}
		},
	},
};
</script>

<style scoped lang="less">
.chrome-icon {
	display: inline-block;
	background: url(../../../assets/img/chrome-icon.png) no-repeat center center;
	background-size: 100% 100%;
	width: 16px;
	height: 16px;
	margin-right: 4px;
}
.open_status {
	display: flex;
	align-items: center;
	pointer-events: none;
	&.standby {
		color: #4c84ff;
		pointer-events: all;
		cursor: pointer;
	}
}
.headernone ::v-deep .ant-modal-header,
.headernone ::v-deep .ant-modal-close {
	display: none;
}
.headernone ::v-deep .ant-btn {
	display: none;
}
.headernone ::v-deep .ant-modal-footer {
	border: none;
	display: none;
}
.headernone ::v-deep .ant-modal-content {
	width: 605px;
	height: 522px;
	background: url("../../../assets/img/yindao/tanbei.png") no-repeat center;
	background-size: 100%;
	box-shadow: none;
}
.headernone ::v-deep .ant-modal-body {
	position: absolute;
	left: 38px;
	top: 176px;
	background: #fffcf0;
	box-shadow: 0px 2px 9px 0px rgba(172, 100, 43, 0.38);
	border-radius: 8px;
	width: 496px;
	height: 334px;
}
.gongsix {
	// width: 216px;
	height: 33px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #2c354b;
	line-height: 33px;
	text-align: center;
}
.gongtow {
	width: 436px;
	height: 44px;
	font-size: 15px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #425070;
	line-height: 22px;
	text-align: center;
}
.gongset {
	width: 353px;
	height: 90px;
	background: #ffedda;
	border-radius: 6px;
	margin: auto;
	margin-top: 24px;
	display: flex;
	position: relative;
	.setleft {
		width: 90px;
		height: 90px;
		background: url("../../../assets/img/yindao/liwu.png") no-repeat center;
		background-size: 100%;
		// position: relative;
	}
	.setconten {
		width: 156px;
		margin-left: 20px;
		padding-top: 21px;
		p {
			// width: 137px;
			height: 25px;
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #ff6f00;
			line-height: 25px;
			margin-bottom: 4px;
		}
		span {
			// width: 156px;
			height: 18px;
			font-size: 13px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #878fa7;
			line-height: 18px;
		}
		.song {
			position: absolute;
			right: 0;
			top: 0;
			width: 24px;
			height: 24px;
			background: #fab9a2;
			border-radius: 0px 6px 0px 6px;
			color: #fff;
			text-align: center;
		}
	}
}
.setbut {
	width: 222px;
	height: 51px;
	background: linear-gradient(180deg, #ff9e14 0%, #ff613b 100%);
	border-radius: 26px;
	border: 1px solid;
	border-image: linear-gradient(134deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.67), rgba(255, 255, 255, 0)) 1 1;
	margin: auto;
	margin-top: 24px;
	color: #fff;
	text-align: center;
	line-height: 51px;
	font-size: 20px;
	font-family: PingFang-SC-Heavy, PingFang-SC;
	font-weight: 800;
	letter-spacing: 2px;
	cursor: pointer;
}
.setdelde {
	width: 28px;
	height: 28px;
	background: url("../../../assets/img/yindao/delde.png") no-repeat center;
	background-size: 100%;
	margin: auto;
	margin-top: 56px;
	cursor: pointer;
}
.bxoli {
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.65);
	position: fixed;
	z-index: 9999;
}
.dakai {
	width: 100%;
	height: 40px;
	position: relative;
	p {
		width: 120px;
		height: 40px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #374567;
		line-height: 40px;
		position: absolute;
		text-align: right;
		right: 5px;
		background: url("../../../assets/img/yindao/dakai.png") no-repeat 1px center;
		background-size: 20px 20px;
		cursor: pointer;
		// display: inline-block;
		// margin-bottom:0px;
		margin: 0;
		// margin-top:none;
	}
	// position: absolute;
	// margin-right: 5px;
}
.yindao {
	width: 100%;
	height: 346px;
	background: url("../../../assets/img/yindao/weitu.png") no-repeat;
	background-size: 100%;
	margin-top: 15px;
	margin-bottom: 10px;
	.dao-top {
		height: 100px;
		width: 100%;
		// border: 1px solid red;
		display: flex;
		justify-content: space-between;
		.dtop-left {
			width: 400px;
			margin-left: 20px;
			.tiot {
				width: 107px;
				height: 21px;
				font-size: 20px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #2c354b;
				line-height: 21px;
				border-left: 6px solid #4c84ff;
				text-align: right;
				margin-top: 28px;
			}
			.txet {
				width: 378px;
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #878fa7;
				line-height: 20px;
			}
		}
		.dtop-right {
			width: 96px;
			height: 22px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #374567;
			line-height: 22px;
			margin-top: 43px;
			margin-right: 21px;
			cursor: pointer;
		}
	}
	.dao-botmm {
		height: 198px;
		width: 100%;
		// border: 1px solid slateblue;
		margin-top: 18px;
		display: flex;
		.nome1:hover {
			margin-top: -15px;
			box-shadow: 0px 2px 22px 0px rgba(37, 75, 100, 0.2);
			cursor: pointer;
		}
		.nome1 {
			width: 584px;
			height: 198px;
			margin-left: 20px;
			background: #ffffff;
			box-shadow: 0px 2px 15px 0px rgba(131, 136, 140, 0.09);
			border-radius: 1px;
			display: flex;
			transition: all 200ms ease-in-out;
			.nome-left {
				display: inline-block;
				width: 210px;
				height: 158px;
				margin: 20px;
			}
			.nome-right {
				display: inline-block;
				margin-left: 20px;
				margin-top: 28px;
				width: 318px;
				.nome-rtitle {
					img {
						width: 26px;
						height: 16px;
					}
					// width: 130px;
					// text-align: right;
					height: 28px;
					font-size: 20px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #2c354b;
					line-height: 25px;
				}
				.nome-center {
					width: 318px;
					height: 66px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #374567;
					line-height: 22px;
					// margin-top: 11px;
					padding-right: 20px;
					box-sizing: border-box;
				}
				.nome-but {
					font-size: 14px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #ffffff;
					line-height: 30px;
					text-align: center;
					width: 80px;
					height: 30px;
					background: #4c84ff;
					border-radius: 2px;
					margin-top: 5px;
					cursor: pointer;
					a {
						text-decoration: none;
						color: #fff;
					}
				}
			}
		}
	}
}
.manage_home_wrap {
	width: 1845px;
	padding-left: 15px;
	margin: 0 auto;

	.top_wrap {
		display: flex;
		// margin-top: 20px;
		justify-content: space-between;

		.top_cell {
			height: 72px;
			background: #ffffff;
			box-shadow: 0px 0px 12px 1px rgba(193, 199, 203, 0.18);
			border-radius: 1px;
			display: flex;

			.t_cell_left {
				width: 4px;
				height: 72px;
				border-radius: 1px;
			}

			.top_cell_ico {
				flex: none;
				width: 30px;
				height: 30px;
				margin-left: 30px;
				margin-top: 22px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100%;
			}

			.top_line {
				width: 1px;
				height: 33px;
				background: #eef2f5;
				margin-left: 20px;
				margin-top: 21px;
			}

			.top_content {
				flex: 1;
				display: flex;
				justify-content: space-around;

				.top_cont_cell {
					width: 116px;

					.top_num {
						font-size: 22px;
						line-height: 22px;
						padding-top: 17px;
						font-weight: bold;
						color: #374567;
						text-align: center;
					}

					.top_txt {
						text-align: center;
						margin-top: 7px;
						color: #878fa7;
					}
				}
			}
		}

		.top_left {
			width: 620px;

			.ico_only {
				background-image: url("../../../assets/img/店铺icon拷贝@2x.png");
			}
		}

		.top_middle {
			width: 620px;

			.ico_only {
				background-image: url("../../../assets/img/设备管理@2x.png");
			}
		}

		.top_right {
			width: 571px;

			.ico_only {
				background-image: url("../../../assets/img/账户余额@2x.png");
			}

			.top_yuer {
				width: 325px;

				.top_num {
					font-size: 22px;
					line-height: 22px;
					padding-top: 17px;
					font-weight: bold;
					color: #374567;
					text-align: center;
				}

				.top_txt {
					text-align: center;
					margin-top: 7px;
					color: #878fa7;
				}
			}

			.chongzhi_btn {
				width: 84px;
				text-align: center;
				cursor: pointer;
				margin-top: 22px;
				line-height: 30px;
				height: 30px;
				background: linear-gradient(270deg, #3dbb9c 0%, #47d3b7 99%);
				border-radius: 2px;
				color: white;
				transition: 0.3s;

				&:hover {
					background: linear-gradient(270deg, #30b393 0%, #4cd8bc 100%);
					transition: 0.3s;
				}
			}
		}
	}

	.down_wrap {
		width: 100%;
		min-height: 400px;
		background-color: white;
		margin-top: 21px;

		.down_top {
			display: flex;
			justify-content: space-between;

			.left {
				display: flex;
			}

			.right {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				flex: 1;

				.saerch-input {
					display: flex;
					width: 338px;
					margin-top: 12px;
					margin-right: 12px;
				}

				.search-select {
					display: flex;
					width: 102px;
					margin-top: 12px;
					margin-right: 12px;

					::v-deep {
						.ant-select-selection {
							width: 100%;
						}

						.ant-select-selection--single {
							width: 100%;
						}
					}
				}
			}

			.top_btn {
				margin-left: 12px;
				width: 102px;
				height: 30px;
				background: #f7f9fd;
				border-radius: 1px;
				text-align: center;
				line-height: 30px;
				cursor: pointer;
				margin-top: 13px;
				color: #878fa7;

				&.blue {
					background: #4c84ff;
					color: #fff;
					margin-right: 14px;

					&:hover {
						color: #fff;
					}
				}
			}

			.top_btn:hover {
				color: #4c84ff;
			}

			.btn_be_check {
				background: #eef2fd;
				color: #4c84ff;
			}

			.btn_1 {
				margin-left: 43px;
			}

			.btn_2 {
				margin-left: 780px;
			}

			.btn_3 {
				margin-left: 12px;
				width: 102px;
				height: 30px;
				background: #4c84ff;
				border-radius: 1px;
				text-align: center;
				line-height: 30px;
				cursor: pointer;
				margin-top: 13px;
				color: white;
			}

			.btn_search {
				margin-left: 11px;
				width: 338px;
				height: 30px;
				margin-top: 12px;
			}

			.btn_select {
				width: 100px;
				margin-left: 11px;
				height: 30px;
				margin-top: 12px;
			}
		}

		.down_table {
			margin-top: 11px;
			margin-left: 14px;
			margin-right: 14px;

			.table_open {
				cursor: pointer;
				text-align: center;
				width: 40px;
				height: 22px;
				background: #ffffff;
				border: 1px solid #d9dde7;
				box-shadow: 0px 2px 6px 0px rgba(157, 163, 178, 0.33);
				border-radius: 2px;
				color: #425070;
			}

			.table_open:hover {
				color: #4c84ff;
			}
		}

		.down_txt {
			margin-top: 11px;
			margin-left: 14px;
		}
	}
}

.cell_info {
	display: flex;
	flex-direction: column;
	justify-content: center;

	p {
		font-size: 14px;
		font-weight: 400;
		color: #425070;
		margin: 0;
	}

	span {
		font-size: 12px;
		font-weight: 400;
		color: #abb4c3;
	}
}

.search-btn {
	margin-right: 12px;
	height: 30px;
	padding: 0 10px;
	background: #f7f9fd;
	border-radius: 1px;
	text-align: center;
	line-height: 30px;
	cursor: pointer;
	margin-top: 13px;
	color: #878fa7;
	background: #4c84ff;
	color: #fff;
	margin-right: 14px;
}

.search-reset {
	margin-right: 12px;
	height: 30px;
	padding: 0 10px;
	background: #f7f9fd;
	border-radius: 1px;
	text-align: center;
	line-height: 30px;
	cursor: pointer;
	margin-top: 13px;
	color: #878fa7;
}
.operate {
	.btns {
		display: flex;
		align-items: center;
		& > img {
			cursor: pointer;
			margin-left: 16px;
			transition: all 0.3s;
			&:hover {
				transform: scale(1.1);
			}
		}
		@keyframes loading {
			from {
				transform: rotateZ(0deg);
			}
			to {
				transform: rotateZ(-360deg);
			}
		}
		button {
			display: flex;
			align-items: center;
			border: none;
			outline: none;
			height: 30px;
			padding: 0 10px;
			border-radius: 4px;
			cursor: pointer;
			font-family: PingFang-SC;
			transition: all 0.3s;
			&[disabled="disabled"] {
				cursor: not-allowed;
				opacity: 0.6;
				img {
					animation: loading 1s linear infinite;
				}
			}
			img {
				margin-right: 4px;
			}
			span {
				color: #fff;
			}
			&.open {
				background-color: #4c84ff;
				&:hover {
					background-color: #2e65de;
				}
			}
			&.close {
				background-color: #ff4d50;
				&:hover {
					background-color: #d94144;
				}
			}
		}
	}
}
</style>
